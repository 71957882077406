import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';

import { ListesGeneriquesService } from '@app/_global/listes-generiques.service';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { TauxTVA } from '@app/salons/taux-tva/taux-tva.model';
import { IEventListener } from '@global/event-manager.service';
import { ArticleSalons } from './articles/article-salon.model';
import { CategorieArticles, CategorieArticlesRow } from './articles/categorie-articles.model';
import { CommandeSalon, CreationCommandeSalon } from './commande/commande.model';
import { ContexteSalon, ReferentSalon } from './contexte-salon/contexte-salon.model';
import { ModeleCommandeSalon } from './modele-commande/modele-commande.model';
import { CodeTypeParticipantSalon, ModuleSalonPossible, Salon } from './salon.model';

import { Cache } from '@app/_helpers/cache';
import { environment } from '@environments/environment';
import { convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, uid } from '@helpers/utils';

export interface ModulePortailIds {
	organisateurs: {
		portails_salons: number[],
		modules_salons: number[],
	}
	exposants: {
		portails_salons: number[],
		modules_salons: number[],
	};
	visiteurs: {
		portails_salons: number[],
		modules_salons: number[],
	};
}

interface CacheTauxTVA {
	total: number,
	tvas: TauxTVA[]
}

interface CacheCategoriesArticles {
	total: number,
	categories: CategorieArticles[]
}

interface CacheArticleSalons {
	total: number,
	articles: ArticleSalons[]
}

interface CacheListeSalons {
	total: number,
	salons: Salon[]
}

interface CacheModeleCommandesSalons {
	total: number,
	modeles_commandes: ModeleCommandeSalon[]
}

interface CacheCommandesSalons {
	total: number,
	commandes: CommandeSalon[]
}

@Injectable({ providedIn: 'root' })
export class SalonService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private cacheTauxTva = new Cache<CacheTauxTVA>();
	private updatingTauxTVA = false; // Empêche la duplication de requête.

	private cacheCategoriesArticles = new Cache<CacheCategoriesArticles>();
	private updatingCategories = false; // Empêche la duplication de requête.

	private cacheListeSalons = new Cache<CacheListeSalons>();

	private cacheArticlesSalons = new Cache<CacheArticleSalons>();
	private cacheModulesSalonsPossibles = new Cache<ModuleSalonPossible[]>();
	private updatingModulesSalonsPossible = false; // Empêche la duplication de requête.

	private cacheModeleCommandesSalons = new Map</*sal_id*/ number, Cache<CacheModeleCommandesSalons>>();
	private cacheCommandesSalons = new Map</*sal_id*/ number, Cache<CacheCommandesSalons>>();

	readonly typesParticipantsSalons: ReadonlyArray<{tps_code: string, tps_libelle: string}>;

	constructor(
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
	) {
		this.typesParticipantsSalons = structuredClone(this.listesGeneriquesService.getListe('types_participants_salons'));
	}

	ngOnDestroy(): void {
		//TODO: désinscrire les callbacks d'events
	}

	/* ====== Taux de TVA ====== */

	public getCacheTVAs(refresh?: boolean) {
		if (refresh !== true && this.cacheTauxTva.isNotEmpty()) {
			return this.cacheTauxTva.currentAsObservable();
		}
		return (
			this.getTVAs()
			.pipe(map(result => {
				this.cacheTauxTva.update(result);
				return result;
			}))
		);
	}

	public getTVAs(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/taux_tva`, tmpParams)
			.pipe(
				map(({ total, taux_tva }) => {
					const result = {
						total: total,
						tvas: Array.from(taux_tva).map((data: any) => new TauxTVA(data))
					};

					return result;
				})
			);
	}

	public getCacheTVA(tva_id: number, refresh?: boolean) {
		if (refresh !== true && this.cacheTauxTva.isNotEmpty()) {
			const tva = this.cacheTauxTva.current()!.tvas.find(tva => tva.tva_id == tva_id);
			if (tva) {
				return new Observable<TauxTVA>(subscriber => {
					subscriber.next(tva);
					subscriber.complete();
				});
			}
		}
		let observable: Observable<CacheTauxTVA>;
		if (this.updatingTauxTVA) {
			// On attend la prochaine valeur du cache.
			observable = this.cacheTauxTva.subject;
		}
		else {
			this.updatingTauxTVA = true;
			observable =
				this.getTVAs()
				.pipe(tap(result => {
					this.updatingTauxTVA = false;
					this.cacheTauxTva.update(result);
				}));
		}

		return observable.pipe(map(result => {
			return result.tvas.find(tva => tva.tva_id == tva_id);
		}));
	}

	public getTVA(tva_id: number) {
		return this.http.get<any>(`${environment.api_url}/taux_tva/${tva_id}`)
			.pipe(map(data => new TauxTVA(data)));
	}

	public createTVA(data: TauxTVA) {
		this.cacheTauxTva.invalidate();

		return this.http.post<any>(`${environment.api_url}/taux_tva`, {
			tva_libelle: data.tva_libelle,
			tva_actif: data.tva_actif,
			tva_taux: data.tva_taux,
		});
	}

	public editTVA(tva: TauxTVA) {
		this.cacheTauxTva.invalidate();

		return this.http.put<any>(`${environment.api_url}/taux_tva/${tva.tva_id}`, {
			tva_libelle: tva.tva_libelle,
			tva_actif: tva.tva_actif,
			tva_taux: tva.tva_taux,
		});
	}

	public deleteTVA(tva_id: number) {
		this.cacheTauxTva.invalidate();

		return this.http.delete<any>(`${environment.api_url}/taux_tva/${tva_id}`);
	}

	/* ====== Catégories articles ====== */

	public getCacheCategoriesArticles(params?: ExtensibleObject, refresh?: boolean) {
		let useCache = (params == undefined) || allPropertiesNullOrUndefined(params);

		if (useCache && refresh !== true && this.cacheCategoriesArticles.isNotEmpty()) {
			return this.cacheCategoriesArticles.currentAsObservable();
		}
		return (
			this.getCategoriesArticles()
			.pipe(map(result => {
				if (useCache) {
					this.cacheCategoriesArticles.update(result);
				}
				return result;
			}))
		);
	}

	public getCategoriesArticles(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/categories_articles_salons`, tmpParams)
			.pipe(
				map(({ total, categories_articles_salons }) => {
					const result = {
						total: total,
						categories: Array.from(categories_articles_salons).map((data: any) => {
							const categorie: CategorieArticles = {
								...data
							};
							return categorie;
						})
					};
					return result;
				})
			);
	}

	public getCacheCategorieArticles(cas_id: number) {
		if (this.cacheCategoriesArticles.isNotEmpty()) {
			const categorie = this.cacheCategoriesArticles.current()!.categories.find(categorie => categorie.cas_id == cas_id);
			if (categorie) {
				return new Observable<CategorieArticlesRow>(subscriber => {
					subscriber.next(categorie);
					subscriber.complete();
				});
			}
		}

		let observable: Observable<CacheCategoriesArticles>;
		if (this.updatingCategories) {
			// On attend la prochaine valeur du cache.
			observable = this.cacheCategoriesArticles.subject;
		} else {
			this.updatingCategories = true;
			observable =
				this.getCategoriesArticles(cas_id)
				.pipe(tap(result => {
					this.updatingCategories = false;
					this.cacheCategoriesArticles.update(result);
				}));
		}

		return observable.pipe(map(result => {
			return result.categories.find(categorie => categorie.cas_id == cas_id);
		}));
	}

	public getCategorieArticles(cas_id: number): Observable<CategorieArticlesRow> {
		return this.http.get<CategorieArticlesRow>(`${environment.api_url}/categories_articles_salons/${cas_id}`);
	}

	public createCategorieArticles(data: Omit<CategorieArticlesRow, 'cas_id'>) {
		this.cacheCategoriesArticles.invalidate();

		return this.http.post<any>(`${environment.api_url}/categories_articles_salons`, {
			cas_libelle: data.cas_libelle,
			cas_ordre: data.cas_ordre,
			cas_type_formulaire: data.cas_type_formulaire,
			cas_description: data.cas_description
		});
	}

	public editCategorieArticle(categorie: CategorieArticlesRow) {
		this.cacheCategoriesArticles.invalidate();

		return this.http.put<any>(`${environment.api_url}/categories_articles_salons/${categorie.cas_id}`, {
			cas_libelle: categorie.cas_libelle,
			cas_ordre: categorie.cas_ordre,
			cas_type_formulaire: categorie.cas_type_formulaire,
			cas_description: categorie.cas_description
		});
	}

	public deleteCategorieArticles(cas_id: number) {
		this.cacheCategoriesArticles.invalidate();

		return this.http.delete<any>(`${environment.api_url}/categories_articles_salons/${cas_id}`);
	}

	/* ====== Articles (non associés à un salon)====== */

	public getCacheArticlesSalons(refresh?: boolean) {
		if (refresh !== true && this.cacheArticlesSalons.isNotEmpty()) {
			return this.cacheArticlesSalons.currentAsObservable();
		}
		return this.getArticlesSalons();
	}

	public getArticlesSalons(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/articles_salons`, tmpParams)
			.pipe(
				map(({ total, articles_salons }) => {
					const result = {
						total: total,
						articles: Array.from(articles_salons).map((data: any) => {
							const article: ArticleSalons = {
								...data
							};
							return article;
						})
					};

					return result;
				})
			);
	}

	public getArticleSalons(ars_id: number) {
		return this.http.get<any>(`${environment.api_url}/articles_salons/${ars_id}`) as Observable<ArticleSalons>;
	}

	public createArticleSalons(data: Omit<ArticleSalons, 'ars_id'>) {
		this.cacheArticlesSalons.invalidate();

		return this.http.post<any>(`${environment.api_url}/articles_salons`, {
			...data
		});
	}

	public editArticleSalons(article: ArticleSalons) {
		this.cacheArticlesSalons.invalidate();

		return this.http.put<any>(`${environment.api_url}/articles_salons/${article.ars_id}`, {
			...article
		});
	}

	public deleteArticleSalons(ars_id: number) {
		this.cacheArticlesSalons.invalidate();

		return this.http.delete<any>(`${environment.api_url}/articles_salons/${ars_id}`);
	}

	/* ====== Salons ====== */

	public getSalons(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/salons`, tmpParams)
			.pipe(
				map(({ total, salons }) => {
					let result  = {
						total: total,
						salons: Array.from(salons).map(this.prepareSalonFromServer)
					};
					return result;
				})
			);
	}

	public getSalon(sal_id: number) {
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}`)
			.pipe(map((data: unknown) => this.prepareSalonFromServer(data)));
	}

	public createSalon(data: Salon, moduleAndPortailIds: ModulePortailIds) {
		this.cacheListeSalons.invalidate();

		const body = this.prepareSalonForServer(data, moduleAndPortailIds);

		return this.http.post<any>(`${environment.api_url}/salons`, body);
	}

	public editSalon(salon: Salon, moduleAndPortailIds: ModulePortailIds) {
		this.cacheListeSalons.invalidate();

		const body = this.prepareSalonForServer(salon, moduleAndPortailIds);

		return this.http.put<any>(`${environment.api_url}/salons/${salon.sal_id}`, body);
	}

	public prepareSalonFromServer(data: unknown) {
		const prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as Salon;
	}

	public prepareSalonForServer(salon: Salon, moduleAndPortailIds: ModulePortailIds) {
		const body = structuredClone(salon) as ExtensibleObject;
		convertDateFieldsToString(body);

		body.organisateurs = moduleAndPortailIds.organisateurs;
		body.exposants = moduleAndPortailIds.exposants;
		body.visiteurs = moduleAndPortailIds.visiteurs;
		return body;
	}

	public deleteSalon(sal_id: number) {
		this.cacheListeSalons.invalidate();

		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}`);
	}

	/* ====== Modules ====== */

	public getModulesSalonsPossibles() {
		//On utilise forcément le cache car les modules possibles ne changent jamais.

		if (this.cacheModulesSalonsPossibles.isNotEmpty()) {
			return this.cacheModulesSalonsPossibles.currentAsObservable();
		}

		let observable: Observable<ModuleSalonPossible[]>;
		if (this.updatingModulesSalonsPossible) {
			// On attend la prochaine valeur du cache.
			observable = this.cacheModulesSalonsPossibles.subject;
		} else {
			this.updatingModulesSalonsPossible = true;
			observable = this.http.get<any>(`${environment.api_url}/modules_salons_possibles`)
				.pipe(map(result => {
					this.updatingModulesSalonsPossible = false;
					this.cacheModulesSalonsPossibles.update(result.modules_salons_possibles);
					return result.modules_salons_possibles as ModuleSalonPossible[];
				}));
		}

		return observable;
	}

	/* ====== Types commandes ====== */

	public getModeleCommandes(sal_id: number, mcs_id: number){
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/modeles_commandes/${mcs_id}`) as Observable<ModeleCommandeSalon>;
	}

	public getCacheModelesCommandes(sal_id: number, refresh?: boolean) {
		let cache = this.cacheModeleCommandesSalons.get(sal_id)
		if (refresh !== true && cache && cache.isNotEmpty()) {
			return cache.currentAsObservable()
		}

		return this.getModelesCommandes(sal_id)
	}

	public getModelesCommandes(sal_id: number, params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/modeles_commandes`, tmpParams)
			.pipe(
				map(({ total, modeles_commandes }) => {
					const result = {
						total: total,
						modeles_commandes: Array.from(modeles_commandes) as ModeleCommandeSalon[]
					};
					return result;
				})
			);
	}


	public createModeleCommande(modeleCommande: ModeleCommandeSalon, sal_id: number){
		this.cacheModeleCommandesSalons.get(sal_id)?.invalidate();

		return this.http.post(`${environment.api_url}/salons/${sal_id}/modeles_commandes`, modeleCommande);
	}

	public editModeleCommande(modeleCommande: ModeleCommandeSalon, sal_id: number, isPartialEdit = false){
		this.cacheModeleCommandesSalons.get(sal_id)?.invalidate();

		let body: unknown = modeleCommande;

		if (isPartialEdit) {
			body = {
				mcs_libelle: modeleCommande.mcs_libelle,
				mcs_description: modeleCommande.mcs_description,
			};
		}

		return this.http.put(`${environment.api_url}/salons/${sal_id}/modeles_commandes/${modeleCommande.mcs_id}`, body);
	}

	public deleteModeleCommande(mcs_id: number, sal_id: number){
		this.cacheModeleCommandesSalons.get(sal_id)?.invalidate();

		return this.http.delete(`${environment.api_url}/salons/${sal_id}/modeles_commandes/${mcs_id}`);
	}

	/* ====== Commandes ====== */

	public getCommande(sal_id: number, cms_id: number){
		return (
			this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes/${cms_id}`)
			.pipe(map((data: any) => this.prepareCommandeFromServer(data)))
		);
	}

	public getCommandes(sal_id: number, params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes`, tmpParams)
			.pipe(
				map(({ total, commandes }) => {
					let result = {
						total: total,
						commandes: Array.from(commandes).map(this.prepareCommandeFromServer)
					};
					return result;
				})
			);
	}

	public createCommande(commande: CreationCommandeSalon, sal_id: number){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();

		let body = structuredClone(commande);
		convertDateFieldsToString(body);

		return this.http.post(`${environment.api_url}/salons/${sal_id}/commandes`, body);
	}

	public editCommande(commande: CommandeSalon, sal_id: number){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();
		let body = this.prepareCommandeForServer(commande);

		return this.http.put(`${environment.api_url}/salons/${sal_id}/commandes/${commande.cms_id}`, body);
	}

	public setCommandeValidation(cms_id: number, sal_id: number, validate: boolean){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();
		return this.http.put(`${environment.api_url}/salons/${sal_id}/commandes/${cms_id}`, {validation: validate});
	}

	public setCommandeBlock(cms_ids: number[], sal_id: number, block: boolean){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();
		return this.http.put(`${environment.api_url}/salons/${sal_id}/commandes/blocage`, {blocage: block, cms_ids: cms_ids});
	}

	public deleteCommande(cms_id: number, sal_id: number){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();

		return this.http.delete(`${environment.api_url}/salons/${sal_id}/commandes/${cms_id}`);
	}

	public prepareCommandeFromServer(data: unknown) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as CommandeSalon;
	}

	public prepareCommandeForServer(commande: CommandeSalon) {
		let body = structuredClone(commande) as ExtensibleObject;
		convertDateFieldsToString(body);
		return body;
	}

	public exportCommandesSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes/export`, tmpParams);
	}

	public exportArticlesCommandesSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes/export_articles`, tmpParams);
	}

	/* ====== Contextes salons ====== */

	public createContexteSalon(ctx_id: number, sal_id: number){
		return this.http.post(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}`, {});
	}

	public createContextesSalons(ctx_ids: number[], sal_id: number){
		return this.http.post(`${environment.api_url}/salons/${sal_id}/contextes`, {
			ctx_ids: ctx_ids
		});
	}

	public getContexteSalons(sal_id: number, params?: unknown){
		const tmpParams = prepareQueryParams(params);

		return (
			this.http.get(`${environment.api_url}/salons/${sal_id}/contextes`, tmpParams)
			.pipe(map((data: any) => {

				const result = {
					total: data.total,
					contextes: Array.from(data.contextes_salons).map(this.prepareContexteSalonFromServer)
				};
				return result;
			}))
		)
	}

	public removeContexteSalon(ctx_id: number, sal_id: number){
		return this.http.delete(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}`);
	}

	public updateReponseParticipationContexteSalon(csa_participation: boolean | null, ctx_id: number, sal_id: number){
		return this.http.put(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}/reponse`, {csa_participation: csa_participation});
	}

	public exportContextesSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/contextes/export`, tmpParams);
	}

	private prepareContexteSalonForServer(contexte: ContexteSalon) {
		let body = structuredClone(contexte) as ExtensibleObject;
		convertDateFieldsToString(body);
		return body;
	}

	private prepareContexteSalonFromServer(data: any) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as ContexteSalon;
	}

	/* ====== Référents salons ====== */

	public getReferentsSalons(sal_id: number, params?: unknown){
		const tmpParams = prepareQueryParams(params);

		return (
			this.http.get(`${environment.api_url}/salons/${sal_id}/referents`, tmpParams)
			.pipe(map((data: any) => {

				let result = {
					total: data.total,
					referents: Array.from(data.referents) as ReferentSalon[]
				};

				return result;
			}))
		);
	}

	public addReferentSalon(uti_id: number, ctx_id: number, sal_id: number){
		return this.http.post(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}/referents/${uti_id}`, {});
	}

	public removeReferentSalon(uti_id: number, ctx_id: number, sal_id: number){
		return this.http.delete(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}/referents/${uti_id}`);
	}

	public exportReferentsSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/referents/export`, tmpParams);
	}

	//

	public getLibelleTypeParticipantSalon(tpsCode: CodeTypeParticipantSalon) {
		return this.typesParticipantsSalons.find(type => type.tps_code == tpsCode)?.tps_libelle;
	}
}

function allPropertiesNullOrUndefined(obj: ExtensibleObject) {
	for (let key in obj) {
		if (obj[key] !== undefined && obj[key] !== null) {
			return false;
		}
	}
	return true;
}