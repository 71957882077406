import { NgModule, Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { AuthService } from '@app/auth/auth.service';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Contexte } from '@app/contexte/contexte.model';
import { EventManagerService } from '@global/event-manager.service';
import { ParametreService } from '@app/parametre/parametre.service';
import { PortailService } from '@app/portail/portail.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { clone, simpleComparison } from '@helpers/utils';

@Component({
	selector: 'utilisateur-form',
	templateUrl: './utilisateur-form.html'
})
export class UtilisateurFormComponent implements OnInit {

	@Input() utilisateur: Utilisateur;
	@Input() contexte: Contexte;
	@Input() ctx_id: number;
	@Input() editMode: boolean;
	@Input() canEdit: boolean;
	@Input() isReferentCreation: boolean = false;
	@Output() updatedEvent = new EventEmitter<boolean>();
	@Output() userCreated = new EventEmitter<number>();
	@Output() userAddedToContexte = new EventEmitter<number>();
	@Output() embedded = false;

	@ViewChild('utilisateurForm', { static: true }) utilisateurForm: NgForm;

	title: string = 'Nouvel utilisateur';
	submitted: boolean = false;
	loading: boolean = false;
	disableForm: boolean = false;
	reinitialisationMotDePasseLoading: boolean = false;
	formDisabled: boolean = false;
	onPortailEqip: boolean = false;
	selfEditing: boolean;
	pristine: Utilisateur;

	instanceName: string;

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private portailService: PortailService,
		private stateService: StateService,
		private utilisateurService: UtilisateurService,
	) {

	}

	ngOnInit(): void {
		this.instanceName = this.parametreService.getParam('instance_name');
		this.onPortailEqip = this.portailService.onPortailEqip();
		this.selfEditing = this.utilisateurService.currentUtilisateurValue.uti_id == this.utilisateur.uti_id;
		// this.utilisateur.uti_actif = (this.utilisateur.uti_actif == '1')? true :false;
		// this.utilisateur.uti_bloque = (this.utilisateur.uti_bloque == '1')? true : false;
		if (this.utilisateur.uti_id) {
			this.title = `${this.utilisateur.uti_prenom} ${this.utilisateur.uti_nom}`;
		}
		else {
			this.editMode = true;
		}

		if (!this.contexte) {
			this.loading = true;
			this.disableForm = true;
			this.portailService.getContexte(this.ctx_id)
			.subscribe(contexte => {
				this.contexte = contexte;
			})
			.add(() => {
				this.loading = false;
				this.disableForm = false;
			});
		}
		this.pristine = clone(this.utilisateur);
		// god awful way to watch for changes
		if (this.utilisateurForm.valueChanges) {
			this.utilisateurForm.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).subscribe(data => {
				this.setUpdatedStatus();
			});
		}
	}

	setUpdatedStatus() {
		const updated = !simpleComparison(this.pristine, this.utilisateur);
		this.updatedEvent.emit(updated);
	}

	checkIfUtilisateurExists() {
		if (this.utilisateur.uti_id) return; // don't check if we're editing an existing user, the api will throw an error if necessary
		let grc_id;
		if (!this.onPortailEqip && !this.portailService.isAdminPartenariat(this.contexte)) {
			grc_id = this.contexte.grc_id;
		}
		return this.utilisateurService.emailExists(this.utilisateur.uti_email, grc_id)
		.subscribe(
			utilisateurExistant => {
				if (utilisateurExistant) {
					let message = 'Un utilisateur avec cette adresse email existe déjà.';
					if (this.portailService.currentPortail.por_code != 'eqip') {
						message = 'Un utilisateur avec cette adresse email existe déjà dans votre groupe.';
					}
					message = `${message} Souhaitez-vous lui donner accès sur le contexte "${this.contexte.ctx_libelle}"`;
					if (this.isReferentCreation) {
						message += ` et l'ajouter en référent ?`;
					}
					else {
						message += ` ?`;
					}

					let uti_id = utilisateurExistant.uti_id;

					this.confirmationService.confirm({
						defaultFocus: 'reject',
						message: message,
						accept: () => {
							this.utilisateurService.addUtilisateurToContexte(uti_id, this.contexte.ctx_id, this.isReferentCreation)
							.subscribe(
								(response: any) => {
									this.eventManager.emit('toast', {severity: 'success', summary: 'Accès au contexte créé'});
									this.pristine = clone(this.utilisateur);
									this.setUpdatedStatus();
									this.userAddedToContexte.emit(uti_id);
									this.stateService.go('^.single', {uti_id: uti_id});
								},
							)
						},
						reject: () => {
							// this.utilisateur.uti_email = null;
						}
					});
				}
			}
		);
	}

	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	submit(): void {
		this.submitted = true;
		if (this.utilisateurForm.invalid) return;
		let utilisateur = Object.assign(new Utilisateur(), this.utilisateur);

		if (!!!this.utilisateur.uti_id) {
			this.loading = true;
			this.disableForm = true;
			this.utilisateurService.post(utilisateur, this.contexte.ctx_id, this.isReferentCreation)
			.subscribe(
				(response: any) => {
					this.eventManager.emit('toast', {severity: 'success', summary: 'Utilisateur ajouté au contexte'});
					this.pristine = clone(this.utilisateur);
					this.setUpdatedStatus();
					this.userCreated.emit(response.uti_id);
					if (!this.embedded) {
						this.stateService.go('^.single', {uti_id: response.uti_id} );
					}
				},
				(error: any) => {
					if (this.portailService.currentPortail.por_code != 'eqip'
						&& error.error
						&& error.error.data
						&& error.error.data.uti_email == 'exists'
					) {
						this.eventManager.emit('toast', {severity: 'info', detail: `Si vous souhaitez lui donner un accès au contexte, veuillez contacter un administrateur ${this.instanceName}.`, sticky: true});
					}
				}
			)
			.add(() => {
				this.loading = false;
				this.disableForm = false;
			});
		}
		else {
			if (this.selfEditing && this.pristine.uti_email != utilisateur.uti_email) {
				this.confirmationService.confirm({
					defaultFocus: 'reject',
					message: 'L\'adresse email liée à ce compte va être modifiée.<br>Cette action déconnectera la session en cours.',
					acceptLabel: 'Continuer',
					rejectLabel: 'Annuler',
					header: 'Confirmation',
					accept: () => {
							this.putUtilisateur(utilisateur, true);
						},
					},
				);
			}
			else {
				this.putUtilisateur(utilisateur);
			}
		}
	}

	putUtilisateur(utilisateur: Utilisateur, logout?: boolean) {
		this.loading = true;
		this.disableForm = true;
		this.utilisateurService.put(utilisateur, this.contexte.ctx_id)
		.subscribe(
			(response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Modification effectuée'});
				this.toggleEditMode(false);
				this.pristine = clone(this.utilisateur);
				this.setUpdatedStatus();
				if (logout) {
					this.eventManager.emit('logout', true);
				}
				else {
					if (this.selfEditing) {
						this.stateService.go('.', undefined, {reload: '^'});
					}
				}
			}
		)
		.add(() => {
			this.loading = false;
			this.disableForm = false;
		});
	}

	resetPassword() {
		this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: `Un mail de réinitialisation de mot de passe sera envoyé à l'adresse mail de l'utilisateur.`,
				acceptLabel: 'Envoyer',
				rejectLabel: 'Annuler',
				header: 'Confirmation',
				accept: () => {
					this.reinitialisationMotDePasseLoading = true;
						this.authService.reinitialisationMotDePasse(this.utilisateur.uti_email)
						.subscribe(
							(response: any) => {
								this.eventManager.emit('toast', {severity: 'success', summary: 'Demande de réinitialisation envoyée', detail: `Un email de réinitialisation de mot de passe à été envoyé à l'adresse ${this.utilisateur.uti_email}`});
							}
						)
						.add(() => { this.reinitialisationMotDePasseLoading = false; });
					},
				}
		);
	}

	disconnect() {
		this.formDisabled = true;
		this.disableForm = true;
		let utilisateur: Utilisateur|undefined = (this.selfEditing)? undefined : this.utilisateur;
		this.utilisateurService.disconnect(utilisateur)
		.subscribe()
		.add(() => {
			this.formDisabled = false;
			this.disableForm = false;
		});
	}

	delete() {
		console.log('show deletion confirmation');
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		PanelModule,
		ToggleButtonModule,
		CiviliteSelectorModule,
	],
	exports: [UtilisateurFormComponent],
	declarations: [UtilisateurFormComponent]
})
export class UtilisateurFormModule { }

