import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { firstValueFrom, map } from 'rxjs';

import { SalonService } from '@app/salons/salon.service'

@Injectable({providedIn: 'root'})
@Pipe({name: 'categorieArticles'})
export class CategorieArticlesPipe implements PipeTransform {

	constructor(private salonService: SalonService) {}

	transform(cas_id: number): Promise<unknown> {
		return firstValueFrom(
			this.salonService.getCacheCategorieArticles(cas_id)
			.pipe(map((categorie) => {
				return categorie?.cas_libelle ?? '';
			}))
		)
	}
}

