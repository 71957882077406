import { repairFloat } from '@app/_helpers/reparation-float';

const PRECISION = 6;

export class TauxTVA {
    tva_id: number = -1;
    tva_taux: number = 0; //Nombre entre 0 et 1.0
    tva_libelle: string = '';
    tva_actif: boolean = true;

    constructor(data?: { tva_id?: number, tva_taux?: number, tva_libelle?: string, tva_actif?: boolean; }) {
        if (data) {
            this.tva_id = data.tva_id ?? -1;
            this.tva_taux = data.tva_taux ?? 0;
            this.tva_libelle = data.tva_libelle ?? '';
            this.tva_actif = data.tva_actif ?? true;
        }
    }

    //Nombre entre 0.0 et 100.0
    get tauxPercent() {
        return repairFloat(this.tva_taux * 100, PRECISION);
    }

    set tauxPercent(valeur: number) {
        this.tva_taux = repairFloat(valeur / 100, PRECISION);
    }

    //Si le taux est de 20,5% la chaine '20,5' sera renvoyée.
    get tauxPercentCommaDecimal(): string {
        return String(this.tauxPercent).replace('.', ',');
    }
}