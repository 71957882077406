import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ListesGeneriquesService } from '@app/_global/listes-generiques.service';
import { EtatSalon } from './salon.model';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'etatSalon' })
export class EtatSalonPipe implements PipeTransform {

	constructor(private listesGeneriquesService: ListesGeneriquesService) { }

	transform(ets_id: number): string {
		const etats = this.listesGeneriquesService.getListe('etats_salons') as EtatSalon[];
		const etat = etats.find((etat: EtatSalon) => etat.ets_id == ets_id);

		if (etat) {
			return etat.ets_libelle;
		}
		return '';
	}
}
