import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

import { ConventionService } from '@app/convention/convention.service';
import { NotificationAdmin } from '@app/notification/notification-admin.model';
import { PortailService } from '@app/portail/portail.service';
import { TypeNotificationAdmin } from '@app/notification/type-notification-admin.model';

import { environment } from '@environments/environment';
import { prepareQueryParams } from '@helpers/prepare-query-params';

import {
	clone,
	convertDateFieldsToDate,
	endOfMonth,
	ExtensibleObject,
	padString,
	startOfMonth,
} from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class NotificationService {

	private typesNotificationAdminSubject = new BehaviorSubject<TypeNotificationAdmin[]>([]);
	readonly typesNotificationAdmin = this.typesNotificationAdminSubject.asObservable();

	constructor(
		private conventionService: ConventionService,
		private http: HttpClient,
		private portailService: PortailService,
	) {
		// this.getTypesNotificationAdmin();
	}

	public get typesNotificationAdminValue() {
		return this.typesNotificationAdminSubject.getValue();
	}

	public getTypesNotificationAdmin() {
		if (this.typesNotificationAdminValue && this.typesNotificationAdminValue.length) {
			return new Observable<any>(subscriber=> {
			 	subscriber.next(clone(this.typesNotificationAdminValue));
				subscriber.complete();
			});
		}
		else {
			return this.http.get<any>(`${environment.api_url}/types_notifications_admin`)
			.pipe(map(response => {
				this.typesNotificationAdminSubject.next(response['types_notifications_admin']);
				return response['types_notifications_admin'];
			}));
		}
	}

	public prepareFromServer(notifications: NotificationAdmin[]) {
		let notifWithConventionLink: string[] = [
			'alerte_rectification_ca',
			'signature_electronique_relecteur',
			'signature_electronique_signataire',
			'signature_electronique_terminee',
			'signature_electronique_expiree',
			'signature_electronique_invalidee',
			'signature_electronique_refusee',
			'signature_relecteur',
			'signature_signataire',
			'signature_terminee',
			'signature_expiree',
			'signature_invalidee',
		];
		return this.getTypesNotificationAdmin()
		.pipe(map(
			typesNotificationAdmin => {
				notifications.forEach((notification: NotificationAdmin) => {
					const currentType = typesNotificationAdmin.find((typesNotificationAdmin: any)  => {return typesNotificationAdmin.tna_id == notification.tna_id; });
					notification.tna_code = currentType.tna_code;
					if (!!currentType.tna_traitement) {
						notification.completable = true;
					}

					// shortcut
					let noa_json_source = notification.noa_json_source;

					convertDateFieldsToDate(noa_json_source, true);

					// links if possible
					switch (notification.por_code) {
						case 'adherent':
							notification.grcLabel = 'Adhérent';
							notification.grcUiSref = 'eqip_adherents.groupes.single';
							notification.ctxLabel = 'Filiale';
							notification.ctxUiSref = 'eqip_filiales.single';
							break;
						case 'fournisseur':
							notification.grcLabel = 'Fournisseur';
							notification.grcUiSref = 'eqip_fournisseurs.groupes.single';
							notification.ctxLabel = 'Partenariat';
							notification.ctxUiSref = 'eqip_partenariats.single';
							break;
						case 'frais_generaux':
							notification.grcLabel = 'Frais généraux';
							notification.grcUiSref = 'eqip_frais_generaux.groupes.single';
							notification.ctxLabel = 'Partenariat';
							notification.ctxUiSref = 'eqip_partenariats_fg.single';
							break;
						case 'eqip':
							notification.grcLabel = 'Groupe';
							notification.grcUiSref = 'eqip.groupement';
							notification.ctxLabel = 'Contexte';
							notification.ctxUiSref = 'groupement';
							break;
					}
					// lien vers convention si on a les infos
					if (notifWithConventionLink.indexOf(notification.tna_code) > -1) {
						const por_code = this.portailService.getPortailCode(noa_json_source.por_id);
						let baseState = 'eqip_partenariats';
						if (por_code == 'frais_generaux') {
							baseState += '_fg';
						}
						// pointer sur la page de saisie
						if (notification.tna_code == 'alerte_rectification_ca') {
							noa_json_source.conUiSref = `${baseState}.single.saisie_ca`;
						}
						else { // sinon sur le formulaire de la convention
							noa_json_source.conUiSref = `${baseState}.single.conventions.single`;
						}
						noa_json_source.conUiParams = {ctx_id: noa_json_source.ctx_par_id, con_id: noa_json_source.con_id};
						if (notification.tna_code.startsWith('signature_')) {
							noa_json_source.conUiParams.tab = 'signatures';
						}

						noa_json_source.label_short = this.conventionService.getConventionNicename(noa_json_source);
						noa_json_source.label = `${noa_json_source.ctx_par_libelle} ${noa_json_source.label_short}`;

					}

					notification.grcUiParams = {grc_id: notification.grc_id};
					notification.ctxUiParams = {ctx_id: notification.ctx_id};

					switch (notification.tna_code) {
						case 'alerte_ajout_pdv':
							if (noa_json_source.adresse) {
								let hasAddress = false;
								for (let field in noa_json_source.adresse) {
									if (!!noa_json_source.adresse[field]) {
										hasAddress = true;
										break;
									}
								}
								if (!hasAddress) delete noa_json_source.adresse
							}
							break;
						case 'salon_confirmation_participation':
						case 'salon_annulation_participation':
						case 'salon_confirmation_commande':
						case 'salon_annulation_commande':
							// On présente les infos du participant au lieu de celle du contexte source

							notification.grcUiParams = {grc_id: noa_json_source.participant_grc_id};
							notification.ctxUiParams = {ctx_id: noa_json_source.participant_ctx_id};
							notification.salonUiParams = {sal_id: noa_json_source.sal_id};
							notification.salonUiLabel = noa_json_source.sal_libelle;

							switch (noa_json_source.participant_por_code) {
								case 'adherent':
									notification.grcLabel = 'Adhérent participant';
									notification.grcUiSref = 'eqip_adherents.groupes.single';
									notification.ctxLabel = 'Filiale';
									notification.ctxUiSref = 'eqip_filiales.single';
									break;
								case 'fournisseur':
									notification.grcLabel = 'Fournisseur participant';
									notification.grcUiSref = 'eqip_fournisseurs.groupes.single';
									notification.ctxLabel = 'Partenariat';
									notification.ctxUiSref = 'eqip_partenariats.single';
									break;
								case 'frais_generaux':
									notification.grcLabel = 'Frais généraux participant';
									notification.grcUiSref = 'eqip_frais_generaux.groupes.single';
									notification.ctxLabel = 'Partenariat';
									notification.ctxUiSref = 'eqip_partenariats_fg.single';
									break;
								case 'eqip':
									notification.grcLabel = 'Groupe participant';
									notification.grcUiSref = 'eqip.groupement';
									notification.ctxLabel = 'Contexte';
									notification.ctxUiSref = 'groupement';
									break;
							}
							break;
					}

				});
				return notifications;
			}
		));
	}

	public getNotificationsAdmin(params: any) {
		let tmpParams = prepareQueryParams(params);
 		return this.http.get<any>(`${environment.api_url}/notifications_admins`, tmpParams)
 		.pipe(flatMap(
 			response => {
 				return this.prepareFromServer(response.notifications_admin)
 				.pipe(map(
 					notifications_admin => {
 						response.notifications_admin = notifications_admin;
 						return response;
 					}
 				))
 			}
 		))
	}

	public getNotificationAdmin(noa_id: number) {
		return this.http.get<any>(`${environment.api_url}/notifications_admins/${noa_id}`)
		.pipe(flatMap(
			response => {
				return this.prepareFromServer([response])
 				.pipe(map(
 					notifications_admin => {
 						return notifications_admin[0];
 					}
 				))
			}
		));
	}

	public setNotificationAdminStatusRead(noa_id: number, status: boolean) {
		let tmpParams = {lue: !!status};
		return this.http.put<any>(`${environment.api_url}/notifications_admins/${noa_id}/lecture`, tmpParams);
	}

	public setAllNotificationAdminStatusRead() {
		return this.http.post<any>(`${environment.api_url}/notifications_admins_lues`, {});
	}

	public setNotificationAdminStatusCompleted(noa_id: number, status: boolean) {
		let tmpParams = {traitee: !!status};
		return this.http.put<any>(`${environment.api_url}/notifications_admins/${noa_id}/traitement`, tmpParams);
	}

	public getCountNotificationsAdmin() {
		// TODO tweak error.interceptor to silence errors for this particular route
		return this.http.get<any>(`${environment.api_url}/notifications_admins_indicateur`);
	}

	public demandeChangementAdmin(ctx_id: number, noa_json_source: any, noa_description?: string) {
		let tmpParams = {
			noa_json_source: noa_json_source,
			noa_description: noa_description
		}
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/demande_changement_admin`, tmpParams);
	}

	public demandeAjoutAgence(ctx_id: number, noa_json_source: any, noa_description?: string) {
		let tmpParams = {
			noa_json_source: noa_json_source,
			noa_description: noa_description
		}
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/demande_ajout_agence`, tmpParams);
	}

	public getAlerteRectificationCA(ctx_id: number, con_id: number) {
		let tmpParams = prepareQueryParams({
			con_id: con_id
		});
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/alerte_rectification_ca`, tmpParams);
	}

	public postAlerteRectificationCA(ctx_id: number, con_id: number, message: string) {
		let tmpParams = {
			con_id: con_id,
			message: message
		}
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/alerte_rectification_ca`, tmpParams);
	}

}
