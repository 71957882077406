<p-panel header="{{title}}">
	<ng-template pTemplate="icons" *ngIf="canEdit">
		<p-button class="label-md-none" label="Editer" icon="pi pi-pencil" (click)="toggleEditMode()" *ngIf="!editMode">
		</p-button>
		<p-button class="label-md-none" label="Enregistrer" icon="pi pi-save" (click)="submit()" *ngIf="!!editMode">
		</p-button>
	</ng-template>


	<p-table
		[value]="[1]"
		styleClass="p-datatable-sm mb-2"
		responsiveLayout="scroll"
	>
		<ng-template pTemplate="body">
			<ng-container *ngFor="let droit of droits">
				<ng-container [ngSwitch]="droit.dro_code">
					<tr *ngSwitchCase="['acces_groupe', 'acces_groupement'] | switchMultiCase: droit.dro_code">
						<td class="col-header col-libelle-xl">
							{{droit.dro_libelle}}
						</td>
						<td class="nowrap text-center">
							<p-selectButton
								[options]="optionsTrueFalse"
								[allowEmpty]="false"
								[(ngModel)]="droit.dru_niveau"
								optionLabel="label"
								optionValue="value"
								[disabled]="!editMode"
								(onChange)="onChange($event)"
							>
								<ng-template let-item>
									<i [class]="item.icon"></i><span class="hide-sm">&nbsp;{{item.label}}</span>
								</ng-template>
							</p-selectButton>
						</td>
						<td class="text-sm">
							{{droit.dro_description}}
						</td>
					</tr>

					<tr *ngSwitchDefault="">
						<td class="col-header">
							{{droit.dro_libelle}}
						</td>
						<td class="nowrap text-center">
							<p-selectButton
								[options]="optionsOuiNon"
								[allowEmpty]="false"
								[(ngModel)]="droit.dru_niveau"
								optionLabel="label"
								optionValue="value"
								[disabled]="!editMode"
								(onChange)="onChange($event)"
								*ngIf="droit.dro_type_booleen"
							>
								<ng-template let-item>
									<i [class]="item.icon"></i><span class="hide-sm">&nbsp;{{item.label}}</span>
								</ng-template>
							</p-selectButton>
							<p-selectButton
								[options]="optionsNiveaux"
								[allowEmpty]="false"
								[(ngModel)]="droit.dru_niveau"
								optionLabel="label"
								optionValue="value"
								[disabled]="!editMode"
								(onChange)="onChange($event)"
								*ngIf="!droit.dro_type_booleen"
							>
								<ng-template let-item>
									<i [class]="item.icon"></i><span class="hide-sm">&nbsp;{{item.label}}</span>
								</ng-template>
							</p-selectButton>
						</td>
						<td class="text-sm">
							{{droit.dro_description}}
						</td>
					</tr>

				</ng-container>

			</ng-container>

		</ng-template>
	</p-table>

</p-panel>
