import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { firstValueFrom, map } from 'rxjs';

import { SalonService } from '@app/salons/salon.service';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'tauxTVA' })
export class TauxTVAPipe implements PipeTransform {

	constructor(private salonService: SalonService) { }

	transform(tva_id: number, option?: 'short'): Promise<unknown> {
		return firstValueFrom(
			this.salonService.getCacheTVA(tva_id)
				.pipe(map((tva) => {
					if (tva == undefined) {
						return '';
					}
					if (option == 'short') {
						return 'TVA ' + tva.tauxPercentCommaDecimal + '%';
					}
					return tva.tva_libelle + ' (' + tva.tauxPercentCommaDecimal + '%)';
				}))
		);
	}
}
